import { Button, DatePicker, Flex, Text, TextInput } from '@patternfly/react-core'
import { DatabaseIcon, PlusIcon, Remove2Icon, SaveIcon, SortAmountDownIcon, SyncAltIcon, TrashAltIcon, TrashIcon } from '@patternfly/react-icons'
import { Toolbar, ToolbarItem, ToolbarContent, ToolbarFilter, ToolbarGroup, Select, SelectOption, SelectVariant } from '@patternfly/react-core'
import React from 'react'
import { cloneDeep, sortBy } from 'lodash'
import { Dropdown, DropdownItem, DropdownList } from '@patternfly/react-core/next'
import { MenuToggle } from '@patternfly/react-core'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../modal/Modal'
import { ChartsOrderComponent } from './Modals/ChartsOrderComponent'
import { SaveViewComponent } from './Modals/SaveViewComponent'
import { LoadViewComponent } from './Modals/LoadViewComponent'
import moment from 'moment-timezone'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

export interface IFilterBase {
  id: string
  name: string
  category: string
  type: 'select' | 'interval' | 'dateRange'
  isActive: boolean
  isDefault: boolean
  source: Record<string, unknown>
}

export interface ISelectFilter extends IFilterBase {
  isExpanded: boolean
  options: ISelectFilterOption[]
}

export interface ISelectFilterOption {
  key: string
  value: string
  label?: string
  meta?: Record<string, any>
  isSelected: boolean
}

export interface IIntervalFilter extends IFilterBase {
  min: string
  max: string
  valueMin: string
  valueMax: string
}

export interface IDateRangeFilter extends IFilterBase {
  valueFrom: string
  valueTo: string
}

export type Filter = ISelectFilter | IIntervalFilter | IDateRangeFilter

interface UserAnalysisFiltersProps {
  config: Record<string, unknown>
  filters: Filter[]
  chartData: Record<string, unknown>[]
  onSelect?: (filters: Filter[]) => void
  onReload?: (filters: Filter[]) => void
  onReorderCharts?: (chartData: Record<string, unknown>[]) => void
  onSaveView?: (viewName: string, viewDescription: string, filters: Filter[]) => void
  onLoadView?: (loadView: Record<string, any>) => void
}

interface UserAnalysisFiltersHandle {
  clear: () => void
  set: (items: Filter[]) => void
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.ForwardRefRenderFunction<UserAnalysisFiltersHandle, UserAnalysisFiltersProps> = (
  props: UserAnalysisFiltersProps,
  ref: React.ForwardedRef<UserAnalysisFiltersHandle>
) => {
  const api = useAxios()
  const [filters, setFilters] = React.useState<Filter[]>(props.filters)

  React.useEffect(() => {
    setFilters(props.filters)
  }, [props.filters])

  React.useEffect(() => {
    if (typeof props.onSelect === 'function') {
      props.onSelect(filters)
    }
  }, [filters])

  const onOptionChipDelete = (_: string, id: string, filterId: string) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterId === filter.id) {
        return {
          ...filter,
          options: (filter as ISelectFilter).options.map((p) => {
            if (p.label === id || p.value === id) {
              return {
                ...p,
                isSelected: false,
              }
            } else {
              return p
            }
          }),
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onOptionChipGroupDelete = (filterId: string) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterId === filter.id) {
        return {
          ...filter,
          options: (filter as ISelectFilter).options.map((p) => {
            return { ...p, isSelected: false }
          }),
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onOptionToggle = (isExpanded: boolean, filterId: string) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterId === filter.id) {
        return {
          ...filter,
          isExpanded,
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onOptionSelect = (event: React.MouseEvent | React.ChangeEvent, selection: string, filterId: string) => {
    const filtersCopy = cloneDeep(filters)
    const filterIndex = filtersCopy.findIndex((n) => n.id === filterId)
    if (filterIndex !== -1) {
      const selectedFilter = filtersCopy[filterIndex] as ISelectFilter
      const option = selectedFilter.options.find((p) => p.value === selection)

      if (option) {
        option.isSelected = (event.target as any).checked as boolean

        setFilters(filtersCopy)
      }
    }
  }

  const getSelectedFilterOptions = (filter, showLabel = false) => {
    return (filter as ISelectFilter).options
      .filter((p) => p.isSelected)
      .map((p) => {
        return showLabel ? p.label || p.value : p.value
      })
  }

  const onIntervalValueChange = (value: string, type: 'valueMin' | 'valueMax', filterId: string) => {
    const nextFilters = cloneDeep(filters)
    const filterIndex = nextFilters.findIndex((n) => n.id === filterId)
    if (filterIndex !== -1) {
      const selectedFilter = nextFilters[filterIndex] as IIntervalFilter
      selectedFilter[type] = value
      setFilters(nextFilters)
    }
  }

  const onDateRangeValueChange = (value: Date | undefined, type: 'valueFrom' | 'valueTo', filterId: string) => {
    const nextFilters = cloneDeep(filters)
    const filterIndex = nextFilters.findIndex((n) => n.id === filterId)
    if (filterIndex !== -1) {
      const selectedFilter = nextFilters[filterIndex] as IDateRangeFilter
      selectedFilter[type] = typeof value === 'undefined' ? '' : moment(value).format('yyyy-MM-DD')
      setFilters(nextFilters)
    }
  }

  const onFilterDeactivate = (filterId: string) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterId === filter.id) {
        if (filter.type === 'select') {
          return {
            ...filter,
            isActive: false,
            options: (filter as ISelectFilter).options.map((p) => {
              return { ...p, isSelected: false }
            }),
          }
        } else if (filter.type === 'interval') {
          return {
            ...filter,
            isActive: false,
          }
        } else if (filter.type === 'dateRange') {
          return {
            ...filter,
            isActive: false,
          }
        } else {
          return filter
        }
      } else {
        return filter
      }
    })

    setFilters(nextFilters)
  }

  const onFilterActivate = (filterId: string, e: Event) => {
    e.preventDefault()

    let matchIndex = -1
    let nextFilters = filters.map((filter, index) => {
      if (filterId === filter.id) {
        matchIndex = index
        return {
          ...filter,
          isActive: true,
        }
      } else {
        return filter
      }
    })

    if (matchIndex !== -1) {
      nextFilters.push(nextFilters.splice(matchIndex, 1)[0])
    }

    setFilters(nextFilters)
  }

  const [isFilterOptionsOpen, setIsFilterOptionsOpen] = React.useState(false)

  const onFilterOptionsToggleClick = () => {
    setIsFilterOptionsOpen(!isFilterOptionsOpen)
  }

  const onFilterOptionsSelect = (_event: React.MouseEvent<Element, MouseEvent> | undefined, itemId: string | number | undefined) => {
    setIsFilterOptionsOpen(false)
  }

  const onReloadClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    if (typeof props.onReload === 'function') {
      props.onReload(filters)
    }
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)

    if (id === chartsOrderModalId && typeof props.onReorderCharts === 'function') {
      const chartData = modalProps.data['chartData'] as Record<string, unknown>[]
      props.onReorderCharts(chartData)
    }

    if (id === saveViewModalId && typeof props.onSaveView === 'function' && typeof modalProps.data['viewName'] === 'string') {
      const viewName = modalProps.data['viewName'] as string
      const viewDescription = modalProps.data['viewDescription'] as string

      props.onSaveView(viewName, viewDescription, filters)
    }

    if (id === loadViewModalId && typeof props.onLoadView === 'function' && modalProps.data['loadView']) {
      props.onLoadView(modalProps.data['loadView'])
    }
  }

  const chartsOrderModalId = 'charts-order-modal'
  const openReorderChartsModal = () => {
    defaultModalProps.title = 'Widgets'
    defaultModalProps.description = `Reorder and choose which widgets to show`
    defaultModalProps.component = ChartsOrderComponent
    defaultModalProps.data = { chartData: props.chartData }
    defaultModalProps.id = chartsOrderModalId
    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const saveViewModalId = 'save-view-modal'
  const openSaveViewModal = () => {
    defaultModalProps.title = 'Save view'
    defaultModalProps.description = `Save current view with filters and charts`
    defaultModalProps.component = SaveViewComponent
    defaultModalProps.data = {}
    defaultModalProps.id = saveViewModalId
    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const loadViewModalId = 'load-view-modal'
  const openLoadViewModal = () => {
    defaultModalProps.title = 'Load view'
    defaultModalProps.description = `Load saved view`
    defaultModalProps.component = LoadViewComponent
    defaultModalProps.data = { config: props.config }
    defaultModalProps.id = loadViewModalId
    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const clearCache = async () => {
    if (confirm(`Clear ${props.config.pageTitle} cache?`)) {
      try {
        await api.current?.post(BackofficeAPIConfig.Domains.Insights, {
          action: BackofficeApiActions.ClearInsightsCache,
          data: { key: props.config.getDataActionName },
        })

        alert('Cleared')
      } catch (error) {
        alert(getErrorMessage(error))
      }
    }
  }

  // date interval filter
  const onDateFormat = (date: Date): string => {
    return moment(date).format('yyyy-MM-DD')
  }
  const onDateParse = (date: string): Date => {
    return moment(date, 'yyyy-MM-DD').toDate()
  }

  // operation addition for select filter (ie. and / or)
  const [operationFiltersToggle, setOperationsFilterToggle] = React.useState<{ filterName: string; isExpanded: boolean }[]>(
    filters.filter((p) => p.source['operations']).map((filter) => ({ filterName: filter.name, isExpanded: false }))
  )
  const onOperationFilterToggle = (filterName: string, isExpanded: boolean) => {
    const next = operationFiltersToggle.map((p) => {
      if (p.filterName === filterName) {
        return {
          filterName,
          isExpanded,
        }
      } else {
        return p
      }
    })
    setOperationsFilterToggle(next)
  }
  const onOperationFilterSelect = (event: React.MouseEvent | React.ChangeEvent, selection: string, filterIndex: number) => {
    const nextFilters = filters.map((filter, index) => {
      if (filterIndex === index) {
        return {
          ...filter,
          source: {
            ...filter.source,
            operations: (filter.source.operations as []).map((p) => ({ key: p['key'], value: p['value'], isSelected: p['value'] === selection ? true : false })),
          },
        }
      } else {
        return filter
      }
    })
    setFilters(nextFilters)
  }

  const toolbarItems = (
    <React.Fragment>
      <Flex style={{ width: '100%', flexWrap: 'nowrap' }}>
        <Flex grow={{ default: 'grow' }}>
          <ToolbarGroup variant="filter-group" style={{ flexWrap: 'wrap' }}>
            {filters.map((filter, index) => (
              <React.Fragment key={index}>
                {/* SELECT filter -------------------------------------------------- */}
                {filter.type === 'select' && (
                  <ToolbarFilter
                    key={`tf-select-${index}`}
                    className="pf-u-display-flex pf-u-align-items-center pf-u-mb-md"
                    variant="overflow-menu"
                    chips={getSelectedFilterOptions(filter, true)}
                    deleteChip={(category, chip) => onOptionChipDelete(category as string, chip as string, filter.id)}
                    deleteChipGroup={() => onOptionChipGroupDelete(filter.id)}
                    categoryName={filter.name}
                    showToolbarItem={filter.isActive}
                  >
                    <Select
                      variant={SelectVariant.checkbox}
                      onToggle={(isExpanded: boolean) => onOptionToggle(isExpanded, filter.id)}
                      onSelect={(event, selection) => onOptionSelect(event, selection as string, filter.id)}
                      selections={getSelectedFilterOptions(filter, false)}
                      isOpen={(filter as ISelectFilter).isExpanded}
                      placeholderText={filter.name}
                      maxHeight={545}
                      removeFindDomNode={true}
                      hasInlineFilter={(filter as ISelectFilter).options.length > 10}
                    >
                      {(filter as ISelectFilter).options
                        .filter((option) => {
                          // Hide ingredients when cosing functionality is selected
                          if (filter.source.columnName === 'ingredient_string') {
                            const selectedIngredients = getSelectedFilterOptions(filter, false)
                            const cosingFilter = filters.find((f) => f.source.columnName === 'cosing_string')
                            if (cosingFilter && !selectedIngredients.includes(option.value)) {
                              const foundOperation = (cosingFilter.source['operations'] as ISelectFilterOption[]).find((n) => n.isSelected)
                              console.log('eh', foundOperation)
                              const isAnd = foundOperation?.value === 'and' || false
                              const selectedCosings = getSelectedFilterOptions(cosingFilter, false)
                              if (selectedCosings.length) {
                                const optionCosings: string[] = option.meta?.cosings || []
                                if (isAnd ? !selectedCosings.every((n) => optionCosings.includes(n)) : !selectedCosings.some((n) => optionCosings.includes(n))) {
                                  return false
                                }
                              }
                            }
                          }

                          // Hide formulations when product type selected
                          if (filter.source.columnName === 'formulation' && filter.source.valueType === 'string') {
                            const selectedFormulations = getSelectedFilterOptions(filter, false)
                            const productTypeFilter = filters.find((f) => f.source.columnName === 'formulation' && f.source.valueType === 'startsWith')
                            if (productTypeFilter) {
                              const selectedProductTypes = getSelectedFilterOptions(productTypeFilter, false)
                              if (selectedProductTypes.length) {
                                const start = option.value[0]
                                if (!selectedProductTypes.includes(start) && !selectedFormulations.includes(option.value)) {
                                  return false
                                }
                              }
                            }
                          }
                          return true
                        })
                        .map((option) => (
                          <SelectOption key={option.key} value={option.value} isChecked={option.isSelected}>
                            {option.label ? option.label : option.value}
                          </SelectOption>
                        ))}
                    </Select>

                    {typeof filter.source['operations'] === 'object' && (filter as ISelectFilter).options.filter((p) => p.isSelected).length > 1 && (
                      <Select
                        variant={SelectVariant.single}
                        onToggle={(isExpanded: boolean) => onOperationFilterToggle(filter.name, isExpanded)}
                        isOpen={operationFiltersToggle.find((p) => p.filterName === filter.name)?.isExpanded}
                        onSelect={(event, selection) => onOperationFilterSelect(event, selection as string, index)}
                        selections={(filter.source['operations'] as ISelectFilterOption[])
                          .filter((p) => p.isSelected)
                          .map((p) => {
                            return p.value
                          })}
                        placeholderText={'Operation'}
                        removeFindDomNode={true}
                        className="pf-u-ml-sm"
                        width={120}
                      >
                        {(filter.source['operations'] as ISelectFilterOption[]).map((option) => (
                          <SelectOption key={option.key} value={option.value} isChecked={option.isSelected}>
                            {option.label ? option.label : option.value}
                          </SelectOption>
                        ))}
                      </Select>
                    )}

                    {!filter.isDefault && (
                      <Button variant="plain" onClick={() => onFilterDeactivate(filter.id)}>
                        <Remove2Icon />
                      </Button>
                    )}
                  </ToolbarFilter>
                )}

                {/* INTERVAL filter -------------------------------------------------- */}
                {filter.type === 'interval' && (
                  <React.Fragment>
                    <ToolbarFilter
                      key={`tf-interval-${index}`}
                      categoryName={filter.name}
                      variant="label"
                      className="pf-u-display-flex pf-u-align-items-center pf-u-flex-wrap pf-u-mb-md"
                      showToolbarItem={filter.isActive}
                      deleteChip={(category, chip) => onFilterDeactivate(filter.id)}
                      chips={filter.isActive ? [`${(filter as IIntervalFilter).valueMin}:${(filter as IIntervalFilter).valueMax}`] : []}
                    >
                      <Text className="pf-u-mr-md">{filter.name}</Text>

                      <TextInput
                        id={`from_${index}`}
                        style={{ width: '60px' }}
                        type="number"
                        placeholder="from"
                        value={(filter as IIntervalFilter).valueMin}
                        onChange={(value) => onIntervalValueChange(value, 'valueMin', filter.id)}
                      ></TextInput>

                      <TextInput
                        id={`to_${index}`}
                        style={{ width: '60px' }}
                        type="number"
                        placeholder="to"
                        value={(filter as IIntervalFilter).valueMax}
                        onChange={(value) => onIntervalValueChange(value, 'valueMax', filter.id)}
                      ></TextInput>

                      {!filter.isDefault && (
                        <Button variant="plain" onClick={() => onFilterDeactivate(filter.id)}>
                          <Remove2Icon />
                        </Button>
                      )}
                    </ToolbarFilter>
                  </React.Fragment>
                )}

                {/* DATE RANGE filter -------------------------------------------------- */}
                {filter.type === 'dateRange' && (
                  <React.Fragment>
                    <ToolbarFilter
                      key={`tf-interval-${index}`}
                      categoryName={filter.name}
                      variant="label"
                      className="pf-u-display-flex pf-u-align-items-center pf-u-flex-wrap pf-u-mb-md"
                      showToolbarItem={filter.isActive}
                      deleteChip={(category, chip) => onFilterDeactivate(filter.id)}
                      chips={filter.isActive ? [`From:${(filter as IDateRangeFilter).valueFrom} To:${(filter as IDateRangeFilter).valueTo}`] : []}
                    >
                      <Text className="pf-u-mr-md">{filter.name}</Text>

                      <DatePicker
                        id={`dateFrom_${index}`}
                        style={{ width: '160px' }}
                        placeholder="From"
                        onChange={(_event, str, date) => onDateRangeValueChange(date, 'valueFrom', filter.id)}
                        value={(filter as IDateRangeFilter).valueFrom}
                        dateFormat={(date) => onDateFormat(date)}
                        dateParse={(date) => onDateParse(date)}
                      />
                      <DatePicker
                        id={`dateTo_${index}`}
                        style={{ width: '160px' }}
                        placeholder="To"
                        onChange={(_event, str, date) => onDateRangeValueChange(date, 'valueTo', filter.id)}
                        value={(filter as IDateRangeFilter).valueTo}
                        dateFormat={(date) => onDateFormat(date)}
                        dateParse={(date) => onDateParse(date)}
                      />

                      {!filter.isDefault && (
                        <Button variant="plain" onClick={() => onFilterDeactivate(filter.id)}>
                          <Remove2Icon />
                        </Button>
                      )}
                    </ToolbarFilter>
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </ToolbarGroup>
        </Flex>
        <Flex>
          <ToolbarGroup variant="filter-group" alignment={{ default: 'alignRight' }}>
            {/* Filter Tools -------------------------------------------------- */}
            <ToolbarItem className="pf-u-mb-md">
              <Dropdown
                isScrollable={true}
                isOpen={isFilterOptionsOpen}
                onSelect={onFilterOptionsSelect}
                minWidth="380px"
                onOpenChange={(isOpen) => setIsFilterOptionsOpen(isOpen)}
                toggle={(toggleRef) => (
                  <MenuToggle ref={toggleRef} aria-label="kebab dropdown toggle" variant="plain" onClick={onFilterOptionsToggleClick} isExpanded={isFilterOptionsOpen}>
                    <PlusIcon />
                  </MenuToggle>
                )}
              >
                <DropdownList>
                  {sortBy(filters, ['name']).map(
                    (filter, index) =>
                      !filter.isActive && (
                        <DropdownItem itemId={filter.id} key={index} onClick={(e) => onFilterActivate(filter.id, e)} isDisabled={filter.isActive}>
                          {filter.name}
                        </DropdownItem>
                      )
                  )}
                </DropdownList>
              </Dropdown>
            </ToolbarItem>

            <ToolbarItem className="pf-u-mb-md">
              <Button variant="plain" onClick={(e) => onReloadClick(e)}>
                <SyncAltIcon></SyncAltIcon>
              </Button>
            </ToolbarItem>

            <ToolbarItem className="pf-u-mb-md">
              <Button variant="plain" onClick={() => openReorderChartsModal()} isInline>
                <SortAmountDownIcon></SortAmountDownIcon>
              </Button>
            </ToolbarItem>

            <ToolbarItem className="pf-u-mb-md">
              <Button variant="plain" onClick={() => openSaveViewModal()} isInline>
                <SaveIcon></SaveIcon>
              </Button>
            </ToolbarItem>

            <ToolbarItem className="pf-u-mb-md">
              <Button variant="plain" onClick={() => openLoadViewModal()} isInline>
                <DatabaseIcon></DatabaseIcon>
              </Button>
            </ToolbarItem>

            <ToolbarItem className="pf-u-mb-md">
              <Button variant="plain" onClick={() => clearCache()} isInline>
                <TrashAltIcon></TrashAltIcon>
              </Button>
            </ToolbarItem>
          </ToolbarGroup>
        </Flex>
      </Flex>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      <Toolbar
        collapseListedFiltersBreakpoint="md"
        clearFiltersButtonText=""
        inset={{
          default: 'insetNone',
        }}
        style={{ paddingBottom: 0 }}
      >
        <ToolbarContent style={{ marginBottom: '-20px' }}>{toolbarItems}</ToolbarContent>
      </Toolbar>
    </React.Fragment>
  )
}

const CustomFilters = React.memo(React.forwardRef(component))

export { CustomFilters }
