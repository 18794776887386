import * as React from 'react'
import { getUniqueId, Modal, ModalVariant, Title, TitleSizes } from '@patternfly/react-core'

import styles from './Modal.module.css'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { TypedEvent } from '@lib/Core/TypedEvent'

export interface IModalBasicComponentProps extends React.ReactElement {
  isModalOpen: boolean
  hasNoBodyWrapper?: boolean
  handleModalToggle: ((id: string) => void) | undefined
  modalProps: IModalBasicComponentModalProps
  toggleModalProps: (props: any) => void
}

export interface IModalBasicComponentModalProps {
  id?: string
  title: string
  description: string
  component: React.FunctionComponent<any>
  data: Record<string, any>
  size?: ModalVariant
}

const component: React.FunctionComponent<IModalBasicComponentProps> = (props: IModalBasicComponentProps) => {
  const modalProps = props.modalProps as IModalBasicComponentModalProps
  const [title, setTitle] = React.useState<string>(modalProps.title)
  const [description, setDescription] = React.useState<string>(modalProps.description)
  const id = props.modalProps.id ? props.modalProps.id : getUniqueId('modal')
  const _hasNoBodyWrapper = props.hasNoBodyWrapper ? props.hasNoBodyWrapper : false

  React.useEffect(() => {
    setTitle(modalProps.title)
  }, [props.modalProps.title])

  React.useEffect(() => {
    setDescription(modalProps.description)
  }, [props.modalProps.description])

  const header = (
    <React.Fragment>
      {props.modalProps && (
        <div className={styles.header}>
          <Title headingLevel="h3">{title}</Title>
          {description ? <p dangerouslySetInnerHTML={{ __html: description }}></p> : ''}
        </div>
      )}
    </React.Fragment>
  )

  const ModalBody: React.FunctionComponent<IGenericComponentInModalProps> = props.modalProps.component

  const onClose = () => {
    _onBeforeCloseModal.emit(props)

    if (typeof props.handleModalToggle === 'function') {
      props.handleModalToggle(id)
    }
  }

  const _onBeforeCloseModal = new TypedEvent<IModalBasicComponentProps>()

  return (
    <Modal
      id={id}
      aria-labelledby="modal-label"
      aria-describedby="modal-description"
      className="custom-modal"
      variant={props.modalProps.size ? props.modalProps.size : ModalVariant.large}
      isOpen={props.isModalOpen}
      onClose={onClose}
      header={header}
      hasNoBodyWrapper={_hasNoBodyWrapper}
    >
      <ModalBody
        {...props.modalProps}
        data={props.modalProps.data}
        closeModal={onClose}
        onBeforeCloseModal={_onBeforeCloseModal}
        setTitle={(text) => {
          console.log('updating title: ' + text)
          setTitle(text)
        }}
        setDescription={(text) => setDescription(text)}
      />
    </Modal>
  )
}

const ModalBasicComponent = React.memo(component)

export { ModalBasicComponent }
