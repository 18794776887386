import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import { Grid, GridItem, Spinner } from '@patternfly/react-core'
import { IDataDefinition, IDataPoint, IDataSet } from '../IData'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { get, isArray } from 'lodash'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'

const componentReadDataSet: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    read: 'Viewing data set',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<number | undefined>(props.data.id)
  const [loading, setLoading] = React.useState<boolean>(true)

  const [dataSet, setDataSet] = React.useState<IDataSet | undefined>(undefined)
  const [mergedDataPoints, setMergedDataPoints] = React.useState<{ dataPoint: IDataPoint; dataDefinition: IDataDefinition }[]>([])

  React.useEffect(() => {
    setLoading(true)
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchDataSet = async (id: number) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Data, {
          action: BackofficeApiActions.ReadDataSet,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as IDataSet
        } else {
          throw new Error('No data set received.')
        }
      } catch (error) {
        throw error
      }
    }

    const fetchDataPoints = async (dataSetId: number) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Data, {
          action: BackofficeApiActions.ListDataPoints,
          data: {
            limit: 0,
            filters: {
              dataSetIds: [dataSetId],
            },
          },
        })
        if (response?.data.result) {
          return response?.data.result as IDataPoint[]
        } else {
          throw new Error('No data points received.')
        }
      } catch (error) {
        throw error
      }
    }

    const fetchDataDefinitions = async (dataDefinitionIds: number[]) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Data, {
          action: BackofficeApiActions.ListDataDefinitions,
          data: {
            limit: 0,
            filters: {
              ids: dataDefinitionIds,
            },
          },
        })
        if (response?.data.result) {
          return response?.data.result as IDataDefinition[]
        } else {
          throw new Error('No data definitions received.')
        }
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id: number) => {
      const ds = await fetchDataSet(id)
      const dp = await fetchDataPoints(ds.id)
      const dd = await fetchDataDefinitions(
        dp.reduce((arr, n) => {
          if (!arr.includes(n.dataDefinitionId)) {
            arr.push(n.dataDefinitionId)
          }
          return arr
        }, [] as number[])
      )
      setDataSet(ds)

      const dataPoints = dp
        .map((dataPoint) => {
          const dataDefinition = dd.find((n) => n.id === dataPoint.dataDefinitionId)
          if (!dataDefinition) {
            throw new Error("Couldn't match data point to data definition.")
          }
          return { dataPoint, dataDefinition }
        })
        .sort((a, b) => {
          return a.dataDefinition.handleTitle.en.localeCompare(b.dataDefinition.handleTitle.en)
        })

      const grouped: Record<string, any[]> = {
        profile: [],
        condition: [],
        skin_disease_assessment: [],
        other_information: [],
        medical_treatment: [],
        other_biomarkers: [],
        study_information: [],
        essential_information: [],
        other: [],
      }

      dataPoints.forEach((item) => {
        if (isArray(grouped[item.dataDefinition.tag])) {
          grouped[item.dataDefinition.tag].push(item)
        } else {
          grouped.other.push(item)
        }
      })

      setMergedDataPoints([
        ...grouped.profile,
        ...grouped.condition,
        ...grouped.skin_disease_assessment,
        ...grouped.other_information,
        ...grouped.medical_treatment,
        ...grouped.other_biomarkers,
        ...grouped.study_information,
        ...grouped.essential_information,
        ...grouped.other,
      ])
    }

    if (id) {
      fetchAll(id)
        .catch((error) => {
          alert(getErrorMessage(error))
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/data/main`)
    })
  })

  const columns = [
    {
      key: 'dataDefinition.handleTitle.en',
      label: 'Text',
    },
    {
      key: 'dataPoint.value',
      label: 'Value',
    },
    {
      key: 'dataDefinition.tagTitle.en',
      label: 'Tag',
    },
    {
      key: 'dataDefinition.handle',
      label: 'Handle',
    },
  ] as { key: string; label: string; format: (val: any) => any }[]

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Grid hasGutter>
          <GridItem span={3}>
            <strong>Customer ID:</strong> {dataSet?.customerId}
          </GridItem>
          <GridItem span={3}>
            <strong>Source:</strong> {dataSet?.source}
          </GridItem>
          <GridItem span={3}>
            <strong>Channel:</strong> {dataSet?.channel}
          </GridItem>
          <GridItem span={3}>
            <strong>Submitted:</strong> {dataSet?.submittedAt}
          </GridItem>
          <GridItem span={12}>
            <TableComposable className="vertical-align-middle" variant={'compact'} borders={true}>
              <Thead>
                <Tr>
                  {columns.map((column, i) => (
                    <Th width={i === 0 ? 20 : undefined} key={column.key}>
                      {column.label}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {mergedDataPoints.length > 0 &&
                  mergedDataPoints.map((result, index) => (
                    <Tr key={index}>
                      {columns.map((column) => (
                        <Td key={column.key}>{column.format ? column.format(get(result, column.key)) : get(result, column.key)}</Td>
                      ))}
                    </Tr>
                  ))}
                {mergedDataPoints.length === 0 && <NoResultsInTable></NoResultsInTable>}
              </Tbody>
            </TableComposable>
          </GridItem>
        </Grid>
      )}
    </React.Fragment>
  )
}

const ReadDataSet = React.memo(componentReadDataSet)

export { ReadDataSet }
