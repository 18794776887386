import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { IPatientConversation } from '@lib/PatientConversation/IPatientConversation'
import { ActionList, ActionListItem, Button, Spinner } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import React from 'react'
import { CustomerConversation } from './CustomerConversation'
import { CustomerConversationCreateForm } from './CustomerConversationCreateForm'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useHistory } from 'react-router-dom'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { usePatientDetails } from '@lib/Patient/usePatientDetails'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [conversations, setConversations] = React.useState<IPatientConversation[]>([])
  const history = useHistory()
  const patientDetails = usePatientDetails(props.data.customerId)

  const columnNames = {
    title: 'Title',
    updated: 'Updated',
    created: 'Created',
  }

  React.useEffect(() => {
    setLoading(true)

    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetAllPatientConversations,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const conversations = response?.data.result as IPatientConversation[]

        setConversations(conversations)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)
  }

  const conversationClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
    e.preventDefault()

    defaultModalProps.title = 'Conversation'
    defaultModalProps.description = ``
    defaultModalProps.component = CustomerConversation
    defaultModalProps.data = { id }

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)

    history.push(`/customer/${props.data['customerId']}/conversations/${id}`)
  }

  const createPatientConversation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    defaultModalProps.title = 'Start conversation'
    defaultModalProps.description = `Recipient: ${patientDetails?.name}`
    defaultModalProps.component = CustomerConversationCreateForm
    defaultModalProps.data = { customerId: props.data.customerId }

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/customer/${props.data.customerId}`)
    })
  })

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <div className="pf-u-mb-xl">
            <ActionList>
              <ActionListItem>
                <Button variant="secondary" isSmall onClick={(e) => createPatientConversation(e)}>
                  Start new conversation
                </Button>
              </ActionListItem>
            </ActionList>
          </div>
          <TableComposable className="vertical-align-middle" variant={'compact'} borders={true}>
            <Thead>
              <Tr>
                <Th>{columnNames.title}</Th>
                <Th>{columnNames.updated}</Th>
                <Th>{columnNames.created}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {conversations.map((conversation, index) => (
                <React.Fragment key={index}>
                  {conversation.messages.length > 0 && (
                    <Tr>
                      <Td dataLabel={columnNames.title}>
                        <a href="#" onClick={(e) => conversationClick(e, conversation.patientConversationId)}>
                          {conversation.messages[0].content.slice(0, 90)}
                        </a>
                      </Td>
                      <Td dataLabel={columnNames.updated}>{moment(conversation.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Td>
                      <Td dataLabel={columnNames.created}>{moment(conversation.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Td>
                    </Tr>
                  )}
                </React.Fragment>
              ))}
              {!conversations.length && <NoResultsInTable></NoResultsInTable>}
            </Tbody>
          </TableComposable>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerConversations = React.memo(component)

export { CustomerConversations }
