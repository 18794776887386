import React from 'react'
import { PageSection, Tabs, Tab, TabContent, TabContentBody, TabTitleText, TextContent, Text, PageSectionVariants } from '@patternfly/react-core'
import { DraftOrderForm } from './DraftOrderForm'
import { CustomerTokenGeneration } from './CustomerTokenGeneration'
import { ListWhatsAppTemplates } from './WhatsApp/ListWhatsAppTemplates'
import { LLMTest } from './LLMTest'

const UtilitiesHome: React.FunctionComponent = () => {
  const [activeTabKey, setActiveTabKey] = React.useState(0)

  // Toggle currently active tab
  const handleTabClick = (tabIndex: number) => setActiveTabKey(tabIndex)

  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">Utilities</Text>
          <Text component="p">Utilities and tools</Text>
        </TextContent>
      </PageSection>
      <PageSection type="tabs">
        <Tabs mountOnEnter activeKey={activeTabKey} onSelect={(_event, tabIndex) => handleTabClick(Number(tabIndex))} usePageInsets id="nested-tabs-list">
          <Tab eventKey={0} title={<TabTitleText>Create draft order</TabTitleText>} tabContentId={`tabContent${0}`}>
            <TabContent id={`tabContent${0}`}>
              <TabContentBody hasPadding children={<DraftOrderForm></DraftOrderForm>}></TabContentBody>
            </TabContent>
          </Tab>
          <Tab eventKey={1} title={<TabTitleText>Authentication tokens generator</TabTitleText>} tabContentId={`tabContent${1}`}>
            <TabContent id={`tabContent${1}`}>
              <TabContentBody hasPadding children={<CustomerTokenGeneration></CustomerTokenGeneration>}></TabContentBody>
            </TabContent>
          </Tab>
          <Tab eventKey={2} title={<TabTitleText>WhatsApp templates</TabTitleText>} tabContentId={`tabContent${2}`}>
            <TabContent id={`tabContent${2}`}>
              <TabContentBody hasPadding children={<ListWhatsAppTemplates></ListWhatsAppTemplates>}></TabContentBody>
            </TabContent>
          </Tab>
        </Tabs>
      </PageSection>
    </React.Fragment>
  )
}

export { UtilitiesHome }
