import { Card, CardBody, PageGroup, PageSection, PageSectionVariants, Text, TextContent } from '@patternfly/react-core'
import React from 'react'

const ChatAssistantIndex: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <PageGroup>
        <PageSection variant={PageSectionVariants.light} hasShadowBottom>
          <TextContent>
            <Text component="h1">AI chat</Text>
            <Text component="p">Prompt and explore results to discover new opportunities.</Text>
          </TextContent>
        </PageSection>
        <PageSection isFilled isWidthLimited>
          <iframe
            style={{ width: '100%', height: '100%' }}
            src="https://noieml.noie.io/?embed=true&embed_options=light_theme"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </PageSection>
      </PageGroup>
    </React.Fragment>
  )
}

export { ChatAssistantIndex }
