import { AxiosResponse } from 'axios'
import * as React from 'react'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table'
import Moment from 'react-moment'
import { Button, CodeBlockCode, ExpandableSection, Label, Spinner } from '@patternfly/react-core'
import { IOrder } from '../IOrder'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../../../components/modal/Modal'
import { CustomerOrderedItems } from './CustomerOrderedItems'
import { NoResultsInTable } from '../../../components/table/NoResultsInTable'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()

  const [orders, setOrders] = React.useState<IOrder[]>([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)

    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetOrders,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const orders = response?.data.result as IOrder[]
        setOrders(orders)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const columnNames = {
    orderId: 'Order ID',
    openedAt: 'Opened at',
    closedAt: 'Closed at',
    name: 'Shopify',
    delivery: 'Webshipper',
    tags: 'Tags',
    properties: 'Properties',
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)
  }

  const openOrderedItemsModal = (order: IOrder) => {
    defaultModalProps.title = 'Ordered items'
    defaultModalProps.description = `Ordered items for order ${order.orderName} - ${order.customerOrderId}`
    defaultModalProps.component = CustomerOrderedItems
    defaultModalProps.data = order

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <TableComposable variant={'compact'} borders={true}>
            <Thead>
              <Tr>
                <Th width={10}>{columnNames.orderId}</Th>
                <Th width={10}>{columnNames.name}</Th>
                <Th width={10}>{columnNames.delivery}</Th>
                <Th width={15}>{columnNames.openedAt}</Th>
                <Th width={15}>{columnNames.closedAt}</Th>
                <Th width={25}>{columnNames.tags}</Th>
                <Th width={15}>{columnNames.properties}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((order, index) => (
                <Tr key={index}>
                  <Td dataLabel={columnNames.orderId}>
                    {order.orderAdminUrl && (
                      <Button isSmall isInline variant="link" onClick={() => openOrderedItemsModal(order)}>
                        {order.customerOrderId}
                      </Button>
                    )}
                    {!order.orderAdminUrl && order.customerOrderId}
                  </Td>
                  <Td dataLabel={columnNames.name}>
                    {order.orderAdminUrl && (
                      <a href={order.orderAdminUrl} target={'_blank'}>
                        {order.orderName}
                      </a>
                    )}
                    {!order.orderAdminUrl && order.orderName}
                  </Td>
                  <Td dataLabel={columnNames.delivery}>
                    {order.orderShippingUrl && (
                      <a href={order.orderShippingUrl} target={'_blank'}>
                        {order.orderName}
                      </a>
                    )}
                    {!order.orderShippingUrl && order.orderName}
                  </Td>
                  <Td dataLabel={columnNames.openedAt}>
                    <Moment date={order.openedAt} />
                  </Td>
                  <Td dataLabel={columnNames.closedAt}>
                    <Moment date={order.closedAt} />
                  </Td>
                  <Td dataLabel={columnNames.tags}>
                    {order.tags?.split(',').map((tag, index) => (
                      <Label key={index} color="blue" isCompact>
                        {tag}
                      </Label>
                    ))}
                  </Td>
                  <Td dataLabel={columnNames.properties}>
                    <ExpandableSection toggleTextExpanded="Show less" toggleTextCollapsed="Show more">
                      <CodeBlockCode id="code-content">{JSON.stringify(order.properties, null, 2).slice(2, -2)}</CodeBlockCode>
                    </ExpandableSection>
                  </Td>
                </Tr>
              ))}
              {!orders.length && <NoResultsInTable></NoResultsInTable>}
            </Tbody>
          </TableComposable>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const CustomerOrders = React.memo(component)

export { CustomerOrders }
