import React from 'react'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import Moment from 'react-moment'
import { isArray } from 'lodash'

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  console.log(props.data)

  const columnNames = {
    source: 'Source',
    created: 'Created',
    evaluation: 'Evaluation',
    reasons: 'Reasons',
    comment: 'Comment',
    author: 'Author',
  }

  const renderReasons = (item: any) => {
    if (item && item.metadata && item.metadata.reasons && item.metadata.reasons.length) {
      return item.metadata.reasons.map((p) => p.description).join(', ')
    } else {
      return ''
    }
  }

  const renderAuthor = (item: any) => {
    if (item && item.metadata && item.metadata.author) {
      return item.metadata.author.name
    } else {
      return ''
    }
  }

  return (
    <React.Fragment>
      <TableComposable variant={'compact'} borders={true}>
        <Thead>
          <Tr>
            <Th>{columnNames.source}</Th>
            <Th>{columnNames.created}</Th>
            <Th>{columnNames.evaluation}</Th>
            <Th>{columnNames.reasons}</Th>
            <Th>{columnNames.comment}</Th>
            <Th>{columnNames.author}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.data.evaluations.map((item, index) => (
            <Tr key={index}>
              <Td dataLabel={columnNames.source}>{item['source']}</Td>
              <Td dataLabel={columnNames.created}>{item.createdAt && <Moment date={item.createdAt} />}</Td>
              <Td dataLabel={columnNames.evaluation}>{item['userFeedback']}</Td>
              <Td dataLabel={columnNames.reasons}>{renderReasons(item)}</Td>
              <Td dataLabel={columnNames.source}>{item['userMessage']}</Td>
              <Td dataLabel={columnNames.reasons}>{renderAuthor(item)}</Td>
            </Tr>
          ))}
        </Tbody>
      </TableComposable>
    </React.Fragment>
  )
}

const CustomerLineItemEvaluations = React.memo(component)

export { CustomerLineItemEvaluations }
