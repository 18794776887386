import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { IWhatsAppTemplate } from '@lib/WhatsApp/IWhatsAppTemplate'
import { Button, Panel, PanelMain, PanelMainBody, Spinner, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import React from 'react'
import { ViewWhatsAppTemplate } from './ViewWhatsAppTemplate'
import { TrashIcon } from '@patternfly/react-icons'
import { CreateWhatsAppTemplate } from './CreateWhatsAppTemplate'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

interface IResult {
  template: IWhatsAppTemplate
  example: Record<string, unknown>
}

const ListWhatsAppTemplates: React.FunctionComponent = () => {
  const api = useAxios()
  const [loading, setLoading] = React.useState(false)
  const [results, setResults] = React.useState<IResult[]>([])
  const [refresh, setRefresh] = React.useState<number>(0)

  React.useEffect(() => {
    setLoading(true)

    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.ListWhatsappTemplates,
      data: {},
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.WhatsApp, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const data = response?.data?.result !== null ? (response.data.result['results'] as IResult[]) : []

        setResults(data)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [refresh])

  const columnNames = {
    id: 'ID',
    name: 'Name',
    language: 'Language',
    category: 'Category',
    status: 'Chatwerk status',
    whatsappStatus: 'WhatsApp status',
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = (id: string) => {
    setIsModalOpen(!isModalOpen)
  }
  const viewTemplateClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, result: IResult) => {
    e.preventDefault()

    defaultModalProps.title = 'WhatsApp Template'
    defaultModalProps.description = ''
    defaultModalProps.component = ViewWhatsAppTemplate
    defaultModalProps.data = { result }

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  const deleteTemplateClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, template: IWhatsAppTemplate) => {
    if (confirm(`Confirm delete of template: ${template.name}`)) {
      await deleteTemplate(template.id)
    }
  }
  const deleteTemplate = async (id: string) => {
    setLoading(true)

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.WhatsApp, {
        action: BackofficeApiActions.DeleteWhatsappTemplate,
        data: {
          templateId: id,
        },
      })

      if (response?.data?.result?.code === 200) {
        setRefresh(refresh + 1)
      } else {
        alert(getErrorMessage(null))
        setLoading(false)
      }
    } catch (error) {
      alert(getErrorMessage(error))
      setLoading(false)
    }
  }

  const createNewTemplateClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    defaultModalProps.title = 'WhatsApp Template'
    defaultModalProps.description = 'Create new'
    defaultModalProps.component = CreateWhatsAppTemplate
    defaultModalProps.data = {}

    setIsModalOpen(!isModalOpen)
    setModalProps(defaultModalProps)
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      <Toolbar id="toolbar-items">
        <ToolbarContent alignment={{ lg: 'alignLeft' }}>
          <ToolbarItem>
            <Button isSmall variant="primary" id="single-group-create-button" onClick={(e) => createNewTemplateClick(e)}>
              Create new template
            </Button>
          </ToolbarItem>
          <ToolbarItem variant="separator" />
          <ToolbarItem>
            <Button isSmall variant="secondary" id="single-group-refresh-button" onClick={(e) => setRefresh(refresh + 1)}>
              Refresh templates
            </Button>
          </ToolbarItem>
        </ToolbarContent>
      </Toolbar>
      <Panel>
        <PanelMain>
          <PanelMainBody>
            {loading && <Spinner />}
            {!loading && (
              <TableComposable className="vertical-align-middle" variant={'compact'} borders={true}>
                <Thead>
                  <Tr>
                    <Th>{columnNames.id}</Th>
                    <Th>{columnNames.name}</Th>
                    <Th>{columnNames.language}</Th>
                    <Th>{columnNames.category}</Th>
                    <Th>{columnNames.status}</Th>
                    <Th>{columnNames.whatsappStatus}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {results.map((result, index) => (
                    <Tr key={index}>
                      <Td dataLabel={columnNames.id}>{result.template.id}</Td>
                      <Td dataLabel={columnNames.name}>
                        <a href="#" onClick={(e) => viewTemplateClick(e, result)}>
                          {result.template.name}
                        </a>
                      </Td>
                      <Td dataLabel={columnNames.language}>{result.template.language}</Td>
                      <Td dataLabel={columnNames.category}>{result.template.category}</Td>
                      <Td dataLabel={columnNames.status}>{result.template.status}</Td>
                      <Td dataLabel={columnNames.whatsappStatus}>{result.template.whatsapp_statuses[0].status}</Td>
                      <Td>
                        <Button variant="link" isDanger onClick={(e) => deleteTemplateClick(e, result.template)}>
                          <TrashIcon />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                  {!results.length && <NoResultsInTable></NoResultsInTable>}
                </Tbody>
              </TableComposable>
            )}
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </React.Fragment>
  )
}

export { ListWhatsAppTemplates }
