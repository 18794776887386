import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import React from 'react'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { AxiosResponse } from 'axios'
import { Button, Pagination, PaginationVariant, Panel, PanelMain, PanelMainBody, Spinner, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { EyeIcon } from '@patternfly/react-icons'
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table'
import { useHistory, useParams } from 'react-router-dom'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { IDataSource } from '../IData'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const ListDataSources: React.FunctionComponent = () => {
  const [refresh, setRefresh] = React.useState<number>(1)

  const api = useAxios()
  const history = useHistory()
  const pathParams = useParams()
  const actions: string[] = []
  const { action, id } = pathParams as Record<string, string | undefined>

  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }

  let initialModalProps: IModalBasicComponentModalProps = defaultModalProps
  let initialShowModal = false

  const toggleRefresh = () => {
    setRefresh(refresh + 1)
  }

  if (actions.includes(action || '') && typeof id === 'string') {
    initialModalProps = {
      component: DefaultModalComponent,
      description: '',
      title: '',
      data: {
        action,
        id: parseInt(id, 10),
        toggleRefresh,
      },
    }
    initialShowModal = true
  }

  const [loading, setLoading] = React.useState(false)
  const [perPage, setPerPage] = React.useState<number>(50)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [totalResults, setTotalResults] = React.useState<number>(0)
  const [results, setResults] = React.useState<IDataSource[]>([])
  const [isModalOpen, setIsModalOpen] = React.useState(initialShowModal)
  const [modalProps, setModalProps] = React.useState(initialModalProps)

  React.useEffect(() => {
    setLoading(true)
    const abortController = new AbortController()

    const countPayload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.CountDataSources,
      data: {},
    }

    const listPayload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.ListDataSources,
      data: {
        limit: perPage,
        page: currentPage - 1,
      },
    }

    Promise.all([api.current?.post(BackofficeAPIConfig.Domains.Data, listPayload), api.current?.post(BackofficeAPIConfig.Domains.Data, countPayload)])
      .then(([r1, r2]: (AxiosResponse<BackofficeApiEventResponse> | undefined)[]) => {
        const r1Results = (r1?.data.result || []) as IDataSource[]
        const r2Results = (r2?.data.result || 0) as number
        setResults(r1Results)
        setTotalResults(r2Results)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [refresh])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const toggleModalProps = (props: Partial<IModalBasicComponentModalProps> = {}) => {
    setModalProps({ ...modalProps, ...props })
  }

  const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
    setCurrentPage(newPage)
    setRefresh(refresh + 1)
  }

  const onPerPageSelect = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPerPage: number, newPage: number) => {
    setPerPage(newPerPage)
    setCurrentPage(newPage)
    setRefresh(refresh + 1)
  }

  const columns = [
    {
      key: 'channel',
      label: 'Channel',
      format(val: string) {
        return val.indexOf('_') ? val.slice(0, 1).toUpperCase() + val.slice(1).replace(/_/g, ' ') : val
      },
    },
    {
      key: 'source',
      label: 'Source',
      format(val: string) {
        return val.indexOf('_') ? val.slice(0, 1).toUpperCase() + val.slice(1).replace(/_/g, ' ') : val
      },
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'amount',
      label: 'Amount',
    },
    {
      key: 'latestSubmission',
      label: 'Latest submission',
      format(val) {
        return new Date(val).toLocaleString('en-GB', { timeZone: 'UTC' })
      },
    },
  ] as { key: string; label: string; format: (val: any) => any }[]

  return (
    <React.Fragment>
      <ModalBasicComponent
        isModalOpen={isModalOpen}
        handleModalToggle={toggleModal}
        modalProps={modalProps}
        toggleModalProps={toggleModalProps}
        key={null}
        type={''}
        props={undefined}
      />

      <Toolbar id="toolbar-items">
        <ToolbarContent alignment={{ lg: 'alignLeft' }}></ToolbarContent>
      </Toolbar>

      <Panel>
        <PanelMain>
          <PanelMainBody>
            <Pagination
              perPageComponent="button"
              itemCount={totalResults}
              widgetId="pagination-options-menu-top"
              perPage={perPage}
              page={currentPage}
              variant={PaginationVariant.top}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            ></Pagination>
            {loading && <Spinner />}
            {!loading && (
              <TableComposable className="vertical-align-middle" variant={'compact'} borders={true}>
                <Thead>
                  <Tr>
                    {columns.map((column) => (
                      <Th key={column.key}>{column.label}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {results.length > 0 &&
                    results.map((result, index) => (
                      <Tr key={index}>
                        {columns.map((column) => (
                          <Td key={column.key}>{column.format ? column.format(result[column.key]) : result[column.key]}</Td>
                        ))}
                      </Tr>
                    ))}
                  {results.length === 0 && <NoResultsInTable></NoResultsInTable>}
                </Tbody>
              </TableComposable>
            )}
            <Pagination
              perPageComponent="button"
              itemCount={totalResults}
              widgetId="pagination-options-menu-bottom"
              perPage={perPage}
              page={currentPage}
              variant={PaginationVariant.bottom}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            ></Pagination>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </React.Fragment>
  )
}

export { ListDataSources }
