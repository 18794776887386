import * as React from 'react'
import { IOrder, IOrderItem } from '../IOrder'
import Moment from 'react-moment'
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table'
import {
  Banner,
  Button,
  Checkbox,
  CodeBlockCode,
  ExpandableSection,
  Flex,
  FlexItem,
  FormGroup,
  Grid,
  GridItem,
  MenuToggle,
  Modal,
  ModalVariant,
  TextArea,
} from '@patternfly/react-core'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { Dropdown, DropdownItem, DropdownList } from '@patternfly/react-core/next'
import update from 'immutability-helper'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { CheckCircleIcon } from '@patternfly/react-icons'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { CustomerLineItemEvaluations } from './CustomerLineItemEvaluations'

interface IManualEvaluationReason {
  id: number
  key: string
  description: string
}

const reasons: IManualEvaluationReason[] = [
  {
    id: 0,
    key: 'efficacy',
    description: 'Efficacy',
  },
  {
    id: 1,
    key: 'adverse_reaction',
    description: 'Adverse reaction',
  },
  {
    id: 2,
    key: 'consistency_texture',
    description: 'Consistency/texture',
  },
  {
    id: 3,
    key: 'smell_fragrance',
    description: 'Smell/fragrance',
  },
  {
    id: 4,
    key: 'packaging_functionality',
    description: 'Packaging functionality',
  },
  {
    id: 5,
    key: 'pricepoint',
    description: 'Pricepoint',
  },
  {
    id: 6,
    key: 'rutine_complexity',
    description: 'Rutine complexity',
  },
  {
    id: 7,
    key: 'miscellaneous',
    description: 'Miscellaneous',
  },
]

interface IManualEvaluationScore {
  value: number
  description: string
}

const scores: IManualEvaluationScore[] = [
  {
    value: 0,
    description: 'Negative',
  },
  {
    value: 1,
    description: 'Positive',
  },
  {
    value: 2,
    description: 'Neutral',
  },
]

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}
const defaultModalProps: IModalBasicComponentModalProps = {
  component: DefaultModalComponent,
  description: '',
  title: '',
  data: {},
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()

  const order = props.data as IOrder
  const rootElementNs = 'line-items'

  const columnNames = {
    customerId: 'Customer ID',
    fulfilledAt: 'Fullfilled at',
    isFulfilled: 'Is fullfilled',
    lineItemId: 'Line item ID',
    quantity: 'Quantity',
    sku: 'SKU',
    title: 'Title',
    properties: 'Properties',
    evaluate: 'Evaluations',
  }

  const [sending, setSending] = React.useState<boolean>(false)
  const [evaluationSuccess, setEvaluationSuccess] = React.useState<boolean>(false)
  const [lineItemToEvaluate, setLineItemToEvaluate] = React.useState<IOrderItem | undefined>(undefined)
  const onClickEvaluate = (item: IOrderItem) => {
    handleEvaluateModalToggle()
    setLineItemToEvaluate(item)
  }
  const onSubmitEvaluation = async () => {
    setSending(true)

    try {
      if (!lineItemToEvaluate) throw new Error('Missing lineItemToEvaluate')
      if (!selectedReasons.length) throw new Error('Missing selectedReasons')
      if (typeof selectedScore !== 'object') throw new Error('Missing selectedScore')

      const response = await api.current?.post(BackofficeAPIConfig.Domains.Customer, {
        action: BackofficeApiActions.CreateEvaluation,
        data: {
          customerId: lineItemToEvaluate.customerId,
          lineItemToEvaluate,
          selectedReasons,
          selectedScore,
          comment: comment ? comment : '',
        },
      })

      setEvaluationSuccess(true)
      setSending(false)

      setTimeout(() => {
        clearEvaluation()
      }, 3000)
    } catch (error) {
      setSending(false)
      alert(getErrorMessage(error))
    }
  }

  const clearEvaluation = () => {
    setLineItemToEvaluate(undefined)
    setSelectedReasons([])
    setSelectedScore(scoreDefaultValue)
    setComment('')
    setEvaluationSuccess(false)
    handleEvaluateModalToggle()
  }

  const [isEvaluateModalOpen, setEvaluateModalOpen] = React.useState(false)
  const handleEvaluateModalToggle = async () => {
    setEvaluateModalOpen(!isEvaluateModalOpen)
  }

  // reasons
  const [selectedReasons, setSelectedReasons] = React.useState<IManualEvaluationReason[]>([])
  const toggleReason = async (checked: boolean, reason: IManualEvaluationReason) => {
    let results: IManualEvaluationReason[] = []

    if (checked) {
      results = update(selectedReasons, { $push: [reason] })
    } else {
      results = selectedReasons.filter((p) => p['id'] !== reason['id'])
    }

    setSelectedReasons(results)
  }

  // dropdown score
  const scoreDefaultValue = 'Select score...'
  const [isScoreDropdownOpen, setScoreDropdownIsOpen] = React.useState(false)
  const [selectedScore, setSelectedScore] = React.useState<IManualEvaluationScore | string>(scoreDefaultValue)
  const onScoreDropdownToggleClick = () => {
    setScoreDropdownIsOpen(!isScoreDropdownOpen)
  }
  const onScoreDropdownSelect = (itemId: string | number | undefined) => {
    const item = itemId as unknown as IManualEvaluationScore

    setSelectedScore(item)
    setScoreDropdownIsOpen(false)
  }

  // textarea comment
  const [comment, setComment] = React.useState('')

  // actions
  const modalActions = evaluationSuccess
    ? []
    : [
        <Button key="confirm" variant="primary" onClick={() => onSubmitEvaluation()} isLoading={sending} isDisabled={sending}>
          Submit
        </Button>,
        <Button key="cancel" variant="link" onClick={() => clearEvaluation()}>
          Cancel
        </Button>,
      ]

  // previous evaluations
  const [isEvaluationsModalOpen, setEvaluationsModalOpen] = React.useState(false)
  const handleEvaluationsModalToggle = async () => {
    setEvaluationsModalOpen(!isEvaluationsModalOpen)
  }
  const [evaluationsModalProps, setEvaluationsModalProps] = React.useState(defaultModalProps)
  const openEvaluationsModal = (item: IOrderItem) => {
    defaultModalProps.title = 'Previous line item evaluations'
    defaultModalProps.description = `${item.title} (${item.sku})`
    defaultModalProps.component = CustomerLineItemEvaluations
    defaultModalProps.data = item

    setEvaluationsModalOpen(!isEvaluationsModalOpen)
    setEvaluationsModalProps(defaultModalProps)
  }

  return (
    <React.Fragment>
      <Modal
        className={`${rootElementNs}-evaluate-modal`}
        variant={ModalVariant.small}
        title="Evaluate line item"
        isOpen={isEvaluateModalOpen}
        showClose={false}
        actions={modalActions}
      >
        <React.Fragment>
          <FormGroup label="Select reasons">
            <Grid span={4} className="pf-u-py-md">
              {reasons.map((reason, index) => (
                <GridItem key={index} className="pf-u-py-xs">
                  <Checkbox
                    isChecked={typeof selectedReasons.find((p) => p.id === reason.id) === 'object'}
                    onChange={(checked) => toggleReason(checked, reason)}
                    label={`${reason.description}`}
                    id={reason.id.toString()}
                  />
                </GridItem>
              ))}
            </Grid>
          </FormGroup>

          <Dropdown
            isOpen={isScoreDropdownOpen}
            onSelect={(e, item) => onScoreDropdownSelect(item)}
            minWidth="400px"
            onOpenChange={(isOpen) => setScoreDropdownIsOpen(isOpen)}
            toggle={(toggleRef) => (
              <MenuToggle ref={toggleRef} isFullWidth onClick={onScoreDropdownToggleClick} isExpanded={isScoreDropdownOpen}>
                <React.Fragment>
                  {typeof selectedScore === 'string' && <span>{selectedScore}</span>}
                  {typeof selectedScore !== 'string' && (
                    <span>
                      <b>Score</b>: {selectedScore.value} ({selectedScore.description})
                    </span>
                  )}
                </React.Fragment>
              </MenuToggle>
            )}
          >
            <DropdownList>
              {scores.map((score, i) => (
                <DropdownItem itemId={score} key={i} description={score.description} onClick={(ev) => ev.preventDefault()}>
                  {score.value}
                </DropdownItem>
              ))}
            </DropdownList>
          </Dropdown>

          <br />

          <TextArea id="comment" rows={4} placeholder="Write a comment..." onChange={(value) => setComment(value)}></TextArea>

          {evaluationSuccess && (
            <React.Fragment>
              <br />
              <br />
              <Banner variant="success">
                <Flex spaceItems={{ default: 'spaceItemsSm' }}>
                  <FlexItem>
                    <CheckCircleIcon />
                  </FlexItem>
                  <FlexItem>Product evaluated successfully</FlexItem>
                </Flex>
              </Banner>
            </React.Fragment>
          )}
        </React.Fragment>
      </Modal>
      <ModalBasicComponent
        isModalOpen={isEvaluationsModalOpen}
        handleModalToggle={handleEvaluationsModalToggle}
        modalProps={evaluationsModalProps}
        toggleModalProps={() => {}}
        key={null}
        type={''}
        props={undefined}
      />
      <TableComposable variant={'compact'} borders={true}>
        <Thead>
          <Tr>
            <Th>{columnNames.customerId}</Th>
            <Th>{columnNames.fulfilledAt}</Th>
            <Th>{columnNames.isFulfilled}</Th>
            <Th>{columnNames.lineItemId}</Th>
            <Th>{columnNames.quantity}</Th>
            <Th>{columnNames.sku}</Th>
            <Th>{columnNames.title}</Th>
            <Th>{columnNames.properties}</Th>
            <Th>{columnNames.evaluate}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {order.items.map((item, index) => (
            <Tr key={index}>
              <Td dataLabel={columnNames.customerId}>{item.customerId}</Td>
              <Td dataLabel={columnNames.fulfilledAt}>{item.isFulfilled && <Moment date={item.fulfilledAt} />}</Td>
              <Td dataLabel={columnNames.isFulfilled}>{item.isFulfilled.toString()}</Td>
              <Td dataLabel={columnNames.lineItemId}>{item.lineItemId}</Td>
              <Td dataLabel={columnNames.quantity}>{item.quantity}</Td>
              <Td dataLabel={columnNames.sku}>{item.sku}</Td>
              <Td dataLabel={columnNames.title}>{item.title}</Td>
              <Td dataLabel={columnNames.properties}>
                <ExpandableSection toggleTextExpanded="Show less" toggleTextCollapsed="Show more">
                  <CodeBlockCode id="code-content">{JSON.stringify(item.properties, null, 2).slice(2, -2)}</CodeBlockCode>
                </ExpandableSection>
              </Td>
              <Td>
                <Button isInline isSmall variant="link" onClick={() => onClickEvaluate(item)}>
                  Evaluate
                </Button>
                {item.evaluations.length > 0 && (
                  <React.Fragment>
                    &nbsp;|&nbsp;
                    <Button isInline isSmall variant="link" onClick={() => openEvaluationsModal(item)}>
                      Previous
                    </Button>
                  </React.Fragment>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </TableComposable>
    </React.Fragment>
  )
}

const CustomerOrderedItems = React.memo(component)

export { CustomerOrderedItems }
