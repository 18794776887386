import * as React from 'react'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { AxiosResponse } from 'axios'
import { Button, Spinner } from '@patternfly/react-core'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { NoResultsInTable } from '../../../components/table/NoResultsInTable'
import Moment from 'react-moment'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '../../../components/modal/Modal'
import { CustomerLineItem } from './CustomerLineItem'
import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { getErrorMessage } from '@lib/Error/getErrorMessage'

interface IEvaluation {
  id: number
  lineItemId: number
  isSuccessful: boolean
  createdAt: Date
  updatedAt: Date
  source: string
  feedback: number
  message: null
}

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const component: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [evaluations, setEvaluations] = React.useState<IEvaluation[]>([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const abortController = new AbortController()

    const payload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.GetEvaluations,
      data: {
        customerId: props.data.customerId,
      },
    }

    api.current
      ?.post(BackofficeAPIConfig.Domains.Customer, payload)
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        const evaluations = response?.data.result as IEvaluation[]
        setEvaluations(evaluations)
        setLoading(false)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const columnNames = {
    id: 'ID',
    lineItemId: 'Line item ID or SKU',
    created: 'Created',
    isSuccessful: 'Is successful?',
    source: 'Source',
    feedback: 'Feedback',
  }

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }
  const [modalProps, setModalProps] = React.useState(defaultModalProps)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const openLineItemModal = (lineItemId: number) => {
    setIsModalOpen(!isModalOpen)
    defaultModalProps.title = 'Line item'
    defaultModalProps.description = ``
    defaultModalProps.component = CustomerLineItem
    defaultModalProps.data = { lineItemId }
    setModalProps(defaultModalProps)
  }

  return (
    <React.Fragment>
      <ModalBasicComponent isModalOpen={isModalOpen} handleModalToggle={toggleModal} modalProps={modalProps} toggleModalProps={() => {}} key={null} type={''} props={undefined} />
      {loading && <Spinner />}
      {!loading && (
        <TableComposable aria-label="Simple table" variant={'compact'} borders={true}>
          <Thead>
            <Tr>
              <Th width={30}>{columnNames.id}</Th>
              <Th width={15}>{columnNames.lineItemId}</Th>
              <Th>{columnNames.created}</Th>
              <Th>{columnNames.isSuccessful}</Th>
              <Th>{columnNames.source}</Th>
              <Th>{columnNames.feedback}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {evaluations.map((evaluation, index) => (
              <Tr key={index}>
                <Td dataLabel={columnNames.id}>{evaluation.id}</Td>
                <Td dataLabel={columnNames.lineItemId}>
                  <React.Fragment>
                    {evaluation.source !== 'website_essential' && (
                      <Button variant="link" isInline onClick={() => openLineItemModal(evaluation.lineItemId)}>
                        {evaluation.lineItemId}
                      </Button>
                    )}
                  </React.Fragment>
                  <React.Fragment>{evaluation.source === 'website_essential' && evaluation.lineItemId}</React.Fragment>
                </Td>
                <Td dataLabel={columnNames.created}>
                  <Moment date={evaluation.createdAt} />
                </Td>
                <Td dataLabel={columnNames.isSuccessful}>{evaluation.isSuccessful.toString()}</Td>
                <Td dataLabel={columnNames.source}>{evaluation.source}</Td>
                <Td dataLabel={columnNames.feedback}>{evaluation.feedback}</Td>
              </Tr>
            ))}
            {!evaluations.length && <NoResultsInTable></NoResultsInTable>}
          </Tbody>
        </TableComposable>
      )}
    </React.Fragment>
  )
}

const CustomerEvaluations = React.memo(component)

export { CustomerEvaluations }
