import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import React from 'react'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { AxiosResponse } from 'axios'
import { Button, Pagination, PaginationVariant, Panel, PanelMain, PanelMainBody, Spinner, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core'
import { NoResultsInTable } from '@app/components/table/NoResultsInTable'
import { EyeIcon, PencilAltIcon } from '@patternfly/react-icons'
import { TableComposable, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table'
import { useHistory, useParams } from 'react-router-dom'
import { IModalBasicComponentModalProps, ModalBasicComponent } from '@app/components/modal/Modal'
import { IDataDefinition } from '../IData'
import { ReadDataDefinition } from './ReadDataDefinition'
import { get } from 'lodash'
import { CreateReadUpdateDataDefinition } from './CreateReadUpdateDataDefinition'

const DefaultModalComponent: React.FunctionComponent = () => {
  return <></>
}

const ListDataDefinitions: React.FunctionComponent = () => {
  const [refresh, setRefresh] = React.useState<number>(1)

  const api = useAxios()
  const history = useHistory()
  const pathParams = useParams()
  const actions = ['read']
  const { action, id } = pathParams as Record<string, string | undefined>

  const defaultModalProps: IModalBasicComponentModalProps = {
    component: DefaultModalComponent,
    description: '',
    title: '',
    data: {},
  }

  let initialModalProps: IModalBasicComponentModalProps = defaultModalProps
  let initialShowModal = false

  const toggleRefresh = () => {
    setRefresh(refresh + 1)
  }

  if (actions.includes(action || '') && typeof id === 'string') {
    initialModalProps = {
      component: ReadDataDefinition,
      description: '',
      title: '',
      data: {
        action,
        id: parseInt(id, 10),
        toggleRefresh,
      },
    }
    initialShowModal = true
  }

  const [loading, setLoading] = React.useState(false)
  const [perPage, setPerPage] = React.useState<number>(50)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [totalResults, setTotalResults] = React.useState<number>(0)
  const [results, setResults] = React.useState<IDataDefinition[]>([])
  const [isModalOpen, setIsModalOpen] = React.useState(initialShowModal)
  const [modalProps, setModalProps] = React.useState(initialModalProps)

  React.useEffect(() => {
    setLoading(true)
    const abortController = new AbortController()

    const countPayload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.CountDataDefinitions,
      data: {},
    }

    const listPayload: BackofficeApiEventSchema = {
      action: BackofficeApiActions.ListDataDefinitions,
      data: {
        limit: perPage,
        page: currentPage - 1,
      },
    }

    Promise.all([api.current?.post(BackofficeAPIConfig.Domains.Data, listPayload), api.current?.post(BackofficeAPIConfig.Domains.Data, countPayload)])
      .then(([r1, r2]: (AxiosResponse<BackofficeApiEventResponse> | undefined)[]) => {
        const r1Results = (r1?.data.result || []) as IDataDefinition[]
        const r2Results = (r2?.data.result || 0) as number
        setResults(r1Results)
        setTotalResults(r2Results)
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [refresh])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }
  const toggleModalProps = (props: Partial<IModalBasicComponentModalProps> = {}) => {
    setModalProps({ ...modalProps, ...props })
  }

  const onSetPage = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPage: number) => {
    setCurrentPage(newPage)
    setRefresh(refresh + 1)
  }

  const onPerPageSelect = (_event: React.MouseEvent | React.KeyboardEvent | MouseEvent, newPerPage: number, newPage: number) => {
    setPerPage(newPerPage)
    setCurrentPage(newPage)
    setRefresh(refresh + 1)
  }

  const clickCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateDataDefinition,
      data: { action: 'create', toggleRefresh },
      description: '',
      title: '',
      id: 'data-definition-modal',
    })

    setIsModalOpen(true)

    history.push('/data/data_definitions/create')
  }

  const clickRead = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateDataDefinition,
      data: { action: 'read', id },
      description: ``,
      title: '',
      id: 'data-definition-modal',
    })

    setIsModalOpen(true)

    history.push('/data/data_definitions/read/' + id)
  }

  const clickUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
    e.preventDefault()

    setModalProps({
      component: CreateReadUpdateDataDefinition,
      data: { action: 'update', id, toggleRefresh },
      description: ``,
      title: '',
      id: 'data-definition-modal',
    })

    setIsModalOpen(true)

    history.push('/data/data_definitions/update/' + id)
  }

  const columns = [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'handle',
      label: 'Handle',
    },
    {
      key: 'handleTitle.en',
      label: 'Text',
    },
    {
      key: 'valueType',
      label: 'Value Type',
    },
  ] as { key: string; label: string; format: (val: any) => any }[]

  return (
    <React.Fragment>
      <ModalBasicComponent
        isModalOpen={isModalOpen}
        handleModalToggle={toggleModal}
        modalProps={modalProps}
        toggleModalProps={toggleModalProps}
        key={null}
        type={''}
        props={undefined}
      />

      <Toolbar id="toolbar-items">
        <ToolbarContent alignment={{ lg: 'alignLeft' }}>
          <Button isSmall variant="primary" id="single-group-create-button" onClick={(e) => clickCreate(e)}>
            Create new data definition
          </Button>
        </ToolbarContent>
      </Toolbar>

      <Panel>
        <PanelMain>
          <PanelMainBody>
            <Pagination
              perPageComponent="button"
              itemCount={totalResults}
              widgetId="pagination-options-menu-top"
              perPage={perPage}
              page={currentPage}
              variant={PaginationVariant.top}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            ></Pagination>
            {loading && <Spinner />}
            {!loading && (
              <TableComposable className="vertical-align-middle" variant={'compact'} borders={true}>
                <Thead>
                  <Tr>
                    {columns.map((column) => (
                      <Th key={column.key}>{column.label}</Th>
                    ))}
                    <Th width={10}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {results.length > 0 &&
                    results.map((result, index) => (
                      <Tr key={index}>
                        {columns.map((column) => (
                          <Td key={column.key}>{column.format ? column.format(get(result, column.key)) : get(result, column.key)}</Td>
                        ))}
                        <Td width={10}>
                          <Toolbar style={{ background: 'transparent', padding: 0 }}>
                            <ToolbarContent alignment={{ default: 'alignRight' }}>
                              <ToolbarGroup alignment={{ default: 'alignRight' }} variant="icon-button-group">
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="view" onClick={(e) => clickRead(e, result.id)}>
                                    <EyeIcon></EyeIcon>
                                  </Button>
                                </ToolbarItem>
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="update" onClick={(e) => clickUpdate(e, result.id)}>
                                    <PencilAltIcon></PencilAltIcon>
                                  </Button>
                                </ToolbarItem>
                              </ToolbarGroup>
                            </ToolbarContent>
                          </Toolbar>
                        </Td>
                      </Tr>
                    ))}
                  {results.length === 0 && <NoResultsInTable></NoResultsInTable>}
                </Tbody>
              </TableComposable>
            )}
            <Pagination
              perPageComponent="button"
              itemCount={totalResults}
              widgetId="pagination-options-menu-bottom"
              perPage={perPage}
              page={currentPage}
              variant={PaginationVariant.bottom}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            ></Pagination>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </React.Fragment>
  )
}

export { ListDataDefinitions }
