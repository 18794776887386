import React from 'react'
import { PageSection, TextContent, Text, PageSectionVariants, TabTitleText, Tabs, Tab, TabContent, TabContentBody } from '@patternfly/react-core'
import { ListMiniModels } from './MiniModel/ListMiniModels'
import { useHistory, useParams } from 'react-router-dom'
import { ListSuccessRateIndicators } from './SuccessRateIndicator/ListSuccessRateIndicators'

const MiniModelHome: React.FunctionComponent = () => {
  // fixed data
  const history = useHistory()
  const pathParams = useParams()
  const sections = ['main', 'success_indicators']
  const { section } = pathParams as Record<string, string | undefined>

  const startTabKey = sections.includes(section || '') ? section : sections[0]

  // reactive data
  const [activeTabKey, setActiveTabKey] = React.useState(startTabKey)

  // Toggle currently active tab
  const handleTabClick = (tabKey: string) => {
    setActiveTabKey(tabKey)
    history.push(`/data_refinement/${tabKey}`)
  }

  return (
    <React.Fragment>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent>
          <Text component="h1">Data refinement</Text>
          <Text component="p">Manage refinement models and success indicators</Text>
        </TextContent>
      </PageSection>
      <PageSection type="tabs">
        <Tabs mountOnEnter activeKey={activeTabKey} onSelect={(_event, tabKey) => handleTabClick(String(tabKey))} usePageInsets id="nested-tabs-list">
          <Tab eventKey={sections[0]} title={<TabTitleText>Data refinement</TabTitleText>} tabContentId={`tabContent${0}`}>
            <TabContent id={`tabContent${0}`}>
              <TabContentBody hasPadding children={<ListMiniModels></ListMiniModels>}></TabContentBody>
            </TabContent>
          </Tab>
          <Tab eventKey={sections[1]} title={<TabTitleText>Success indicators</TabTitleText>} tabContentId={`tabContent${1}`}>
            <TabContent id={`tabContent${1}`}>
              <TabContentBody hasPadding children={<ListSuccessRateIndicators></ListSuccessRateIndicators>}></TabContentBody>
            </TabContent>
          </Tab>
        </Tabs>
      </PageSection>
    </React.Fragment>
  )
}

export { MiniModelHome }
