import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { useAxios } from '@app/utils/useAxios'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ISkincareFormulation, ISkincareIngredient, ISkincareProduct, ISkincareProductUpdateDTO } from '../IProduct'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeApiEventSchema } from '@lib/Core/API/BackofficeApiEventSchema'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { AxiosResponse } from 'axios'
import {
  ActionGroup,
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Grid,
  GridItem,
  Spinner,
  TextInput,
  TextInputGroup,
  TextInputGroupMain,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core'
import { TagInput } from '@app/components/textInput/TagInput'
import { PlusIcon, TrashIcon } from '@patternfly/react-icons'

const componentCreateReadUpdateProduct: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  // Fixed
  const api = useAxios()
  const history = useHistory()
  const modalTitles = {
    create: 'Creating product',
    read: 'Viewing product',
    update: 'Updating product',
  }

  // Reactive
  const [action, setAction] = React.useState<string>(props.data.action)
  const [id, setId] = React.useState<string | undefined>(props.data.id)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [sending, setSending] = React.useState<boolean>(false)
  const [isReadOnly, setIsReadOnly] = React.useState<boolean>(action === 'read')
  const [validated, setValidated] = React.useState<string>('default')
  const [saved, setSaved] = React.useState<boolean>(false)

  const [product, setProduct] = React.useState<ISkincareProduct | undefined>(undefined)

  const [formSku, setFormSku] = React.useState<string>('')
  const [formProductType, setFormProductType] = React.useState<string>('')
  const [formProductName, setFormProductName] = React.useState<string>('')
  const [formOptions, setFormOptions] = React.useState<{ name: string; value: string }[]>([])
  const [formFormulationId, setFormFormulationId] = React.useState<string>('')
  const [helperFormulations, setHelperFormulations] = React.useState<ISkincareFormulation[]>([])

  React.useEffect(() => {
    setLoading(true)
    console.log(action, id, modalTitles[action])
    props.setTitle(modalTitles[action])

    const abortController = new AbortController()

    const fetchProduct = async (id: string) => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Product, {
          action: BackofficeApiActions.ReadProduct,
          data: {
            id,
          },
        })
        if (response?.data.result) {
          return response?.data.result as ISkincareProduct
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchFormulations = async () => {
      try {
        const response = await api.current?.post(BackofficeAPIConfig.Domains.Product, {
          action: BackofficeApiActions.ListFormulations,
          data: {
            limit: 0,
          },
        })

        if (response?.data.result) {
          return response?.data.result as ISkincareFormulation[]
        }
        return undefined
      } catch (error) {
        throw error
      }
    }

    const fetchAll = async (id: string | undefined) => {
      let tempFormOptions = [] as { name: string; value: string }[]

      if (id) {
        const product = await fetchProduct(id)
        if (product) {
          setProduct(product)
          setFormSku(product.sku)
          setFormProductType(product.productType)
          setFormProductName(product.productName)
          tempFormOptions = Object.keys(product.options).map((key) => ({ name: key, value: product.options[key] }))
          setHelperFormulations([product.formulation])
          setFormFormulationId(product.formulationId)
        }
      }

      if (['create', 'update'].includes(action)) {
        tempFormOptions = tempFormOptions.concat([{ name: '', value: '' }])
        const formulations = await fetchFormulations()
        if (formulations) {
          setHelperFormulations(formulations)
        }
      }

      setFormOptions(tempFormOptions)
      return true
    }

    fetchAll(id)
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [action])

  React.useEffect(() => {
    props.onBeforeCloseModal.once((_props) => {
      history.push(`/product/main`)
    })
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (![formSku, formProductType, formProductName, formOptions].every((n) => n)) {
      setValidated('error')
      return
    }

    let defaultData = product || { metadata: {}, options: {} }

    let data: Partial<ISkincareProductUpdateDTO> = {
      ...defaultData,
      sku: formSku,
      productType: formProductType,
      productName: formProductName,
      formulationId: formFormulationId,
      options: formOptions.reduce((obj, item) => {
        if (item.name && item.value) {
          obj[item.name] = item.value
        }
        return obj
      }, {} as Record<string, string>),
    }

    setSending(true)
    setSaved(false)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Product, {
        action: action === 'create' ? BackofficeApiActions.CreateProduct : BackofficeApiActions.UpdateProduct,
        data,
      } as BackofficeApiEventSchema)
      .then((response: AxiosResponse<BackofficeApiEventResponse> | undefined) => {
        if (response?.data.result) {
          const { id } = response.data.result as ISkincareProduct
          if (action === 'create') {
            setAction('update')
            setId(id)
            history.replace('/product/main/update/' + id)
          }
          setSaved(true)
          if (props.data.toggleRefresh) {
            props.data.toggleRefresh()
          }
        } else {
          throw new Error('Failed to get response.')
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })
      .finally(() => {
        setSending(false)
      })
  }

  const updateOptionName = (index: number, name: string) => {
    setFormOptions(
      formOptions.map((n, i) => {
        if (i === index) {
          n.name = name
        }
        return n
      })
    )
  }

  const updateOptionValue = (index: number, value: string) => {
    setFormOptions(
      formOptions.map((n, i) => {
        if (i === index) {
          n.value = value
        }
        return n
      })
    )
  }

  const clickAddOption = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    setFormOptions([...formOptions, { name: '', value: '' }])
  }

  const clickRemoveOption = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.preventDefault()

    setFormOptions(formOptions.filter((_, i) => i !== index))
  }

  return (
    <React.Fragment>
      {loading && <Spinner></Spinner>}
      {!loading && (
        <Form onSubmit={handleSubmit}>
          {validated === 'error' && (
            <FormAlert>
              <Alert variant="danger" title="Fill out all required fields before continuing." aria-live="polite" isInline />
            </FormAlert>
          )}
          {saved && (
            <FormAlert>
              <Alert variant="success" title="Product saved." aria-live="polite" isInline />
            </FormAlert>
          )}
          <Grid hasGutter>
            <GridItem span={6}>
              <FormGroup label="Product Name" isRequired>
                <TextInput isRequired id="formProductName" type="text" value={formProductName} readOnly={isReadOnly} onChange={(val) => setFormProductName(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Product Type" isRequired>
                <TextInput isRequired id="formProductType" type="text" value={formProductType} readOnly={isReadOnly} onChange={(val) => setFormProductType(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="SKU" isRequired>
                <TextInput isRequired id="formSku" type="text" value={formSku} readOnly={isReadOnly} onChange={(val) => setFormSku(val)}></TextInput>
              </FormGroup>
            </GridItem>
            <GridItem span={6}>
              <FormGroup label="Formulation" isRequired>
                <FormSelect isRequired id="formFormulationId" value={formFormulationId} readOnly={isReadOnly} onChange={(val) => setFormFormulationId(val)}>
                  {helperFormulations.map((option, index) => (
                    <FormSelectOption key={index} value={option.id} label={option.code} />
                  ))}
                </FormSelect>
              </FormGroup>
            </GridItem>
            <GridItem span={12}>
              <FormGroup label="Options" isRequired>
                {formOptions.map((item, index) => (
                  <Grid key={'option' + index} hasGutter>
                    <GridItem span={4}>
                      <TextInput
                        isRequired
                        placeholder="name"
                        id={'formOptionName' + index}
                        type="text"
                        value={item.name}
                        readOnly={isReadOnly}
                        onChange={(val) => updateOptionName(index, val)}
                      ></TextInput>
                    </GridItem>
                    <GridItem span={4}>
                      <TextInput
                        isRequired
                        placeholder="value"
                        id={'formOptionValue' + index}
                        type="text"
                        value={item.value}
                        readOnly={isReadOnly}
                        onChange={(val) => updateOptionValue(index, val)}
                      ></TextInput>
                    </GridItem>
                    <GridItem span={4}>
                      {!isReadOnly && (
                        <Toolbar>
                          <ToolbarContent alignment={{ default: 'alignRight' }}>
                            <ToolbarGroup alignment={{ default: 'alignRight' }} variant="icon-button-group">
                              {index + 1 === formOptions.length && (
                                <ToolbarItem>
                                  <Button variant="plain" aria-label="view" onClick={(e) => clickAddOption(e)}>
                                    <PlusIcon></PlusIcon>
                                  </Button>
                                </ToolbarItem>
                              )}
                              <ToolbarItem>
                                <Button variant="plain" aria-label="delete" onClick={(e) => clickRemoveOption(e, index)}>
                                  <TrashIcon></TrashIcon>
                                </Button>
                              </ToolbarItem>
                            </ToolbarGroup>
                          </ToolbarContent>
                        </Toolbar>
                      )}
                    </GridItem>
                  </Grid>
                ))}
              </FormGroup>
            </GridItem>
            {/* insert options field */}
            {!isReadOnly && (
              <GridItem offset={9} span={3}>
                <ActionGroup style={{ float: 'right' }}>
                  <Button variant="primary" type="submit" isLoading={sending}>
                    Submit
                  </Button>
                  <Button variant="link" type="button" onClick={() => props.closeModal()}>
                    Cancel
                  </Button>
                </ActionGroup>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </React.Fragment>
  )
}

const CreateReadUpdateProduct = React.memo(componentCreateReadUpdateProduct)

export { CreateReadUpdateProduct }
