import { IGenericComponentInModalProps } from '@app/components/modal/IGenericComponentInModalProps'
import { TypeaheadSelect } from '@app/components/select/TypeaheadSelect'
import { useAxios } from '@app/utils/useAxios'
import { BackofficeApiActions } from '@lib/Core/API/BackofficeApiActions'
import { BackofficeAPIConfig } from '@lib/Core/API/BackofficeAPIConfig'
import { BackofficeApiEventResponse } from '@lib/Core/API/BackofficeApiEventResponse'
import { getErrorMessage } from '@lib/Error/getErrorMessage'
import { IBayesianModel, IBayesianModelCluster, IBayesianModelClusterPrior } from '@lib/Model/BayesianModel'
import { Button, Divider, Form, FormGroup, Grid, GridItem, Label, Spinner, Text, TextInput } from '@patternfly/react-core'
import { SelectOptionProps } from '@patternfly/react-core/next'
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table'
import { AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import React from 'react'

const columnNames = {
  id: 'Prior ID',
  sku: 'SKU',
  prior: 'Prior',
  created: 'Created',
  lastModified: 'Last modified',
  actions: '',
}

interface INewPrior {
  prior?: number
  sku?: string
}

const BMClusterPriors: React.FunctionComponent<IGenericComponentInModalProps> = (props: IGenericComponentInModalProps) => {
  const api = useAxios()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [priors, setPriors] = React.useState<IBayesianModelClusterPrior[]>([])
  const [reloadPriors, setReloadPriors] = React.useState<number>(0)
  const [newPrior, setNewPrior] = React.useState<INewPrior>({ prior: 0 })
  const [modelProducts, setModelProducts] = React.useState<SelectOptionProps[]>([])

  const model = props.data.model as IBayesianModel
  const cluster = props.data.cluster as IBayesianModelCluster

  React.useEffect(() => {
    const abortController = new AbortController()

    setLoading(true)

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.ListBMModelClusterPriors,
        data: {
          modelId: cluster.modelId,
          clusterId: cluster.clusterId,
        },
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          setPriors(response.data.result as IBayesianModelClusterPrior[])
          setLoading(false)
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }
  }, [reloadPriors])

  React.useEffect(() => {
    const abortController = new AbortController()

    api.current
      ?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.GetBMFormModel,
        data: {},
      })
      .then((response: AxiosResponse<BackofficeApiEventResponse>) => {
        if (response && response.data && response.data.result) {
          const products = response.data.result['products'] as SelectOptionProps[]
          setModelProducts(products.filter((p) => model.skuList.includes(p['itemId'].substring(0, 3))))
        }
      })
      .catch((error) => {
        alert(getErrorMessage(error))
      })

    return () => {
      abortController.abort()
    }
  }, [])

  const deletePrior = async (prior: IBayesianModelClusterPrior) => {
    if (!confirm(`Delete prior ${prior.priorId}?`)) return

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.DeleteBMModelClusterPrior,
        data: { priorId: prior.priorId },
      })

      if (response?.status === 200) {
        setReloadPriors(reloadPriors + 1)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
    }
  }

  type TypeaheadSelectHandle = React.ElementRef<typeof TypeaheadSelect>
  const typeaheadSelectRef = React.useRef<TypeaheadSelectHandle>(null)
  const [sending, setSending] = React.useState<boolean>(false)
  const createPrior = async () => {
    setSending(true)

    try {
      const response = await api.current?.post(BackofficeAPIConfig.Domains.Model, {
        action: BackofficeApiActions.CreateBMModelClusterPrior,
        data: {
          modelId: cluster.modelId,
          clusterId: cluster.clusterId,
          ...newPrior,
        },
      })

      if (response?.status === 200) {
        setTimeout(() => {
          setReloadPriors(reloadPriors + 1)
          setNewPrior({ prior: 0 })
          setSending(false)
          typeaheadSelectRef.current?.clear()
        }, 1000)
      } else {
        throw new Error(`Error: ${JSON.stringify(response?.data)}`)
      }
    } catch (error) {
      alert(getErrorMessage(error))
      setSending(false)
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={createPrior} isHorizontal>
        <FormGroup isStack label="Create new">
          <Grid hasGutter>
            <GridItem span={2}>
              <TextInput
                isRequired
                type="number"
                id="txtPrior"
                name="txtPrior"
                placeholder="Prior value"
                value={newPrior?.prior}
                onChange={(value) => setNewPrior({ ...newPrior, prior: value ? parseFloat(value) : 0 })}
              />
            </GridItem>
            <GridItem span={8}>
              <TypeaheadSelect
                ref={typeaheadSelectRef}
                items={modelProducts}
                placeholder="Select a product..."
                onClear={() => setNewPrior({ ...newPrior, sku: '' })}
                onSelect={(itemId) => setNewPrior({ ...newPrior, sku: itemId })}
              ></TypeaheadSelect>
            </GridItem>
            <GridItem span={2}>
              <Button variant="tertiary" isBlock isDisabled={newPrior.sku && newPrior.sku.length > 0 ? false : true} isLoading={sending} onClick={() => createPrior()}>
                Add new
              </Button>
            </GridItem>
          </Grid>
        </FormGroup>
      </Form>
      <Divider className="pf-u-mt-lg pf-u-mb-lg"></Divider>
      {loading && <Spinner />}
      {!loading && priors.length > 0 && (
        <React.Fragment>
          <TableComposable className="vertical-align-middle" variant={'compact'} borders={true}>
            <Thead>
              <Tr>
                <Th>{columnNames.id}</Th>
                <Th>{columnNames.sku}</Th>
                <Th>{columnNames.prior}</Th>
                <Th>{columnNames.created}</Th>
                <Th>{columnNames.lastModified}</Th>
                <Th width={30}>{columnNames.actions}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {priors.map((prior, index) => (
                <Tr key={index}>
                  <Td dataLabel={columnNames.id}>{prior.priorId}</Td>
                  <Td dataLabel={columnNames.sku}>{prior.sku}</Td>
                  <Td dataLabel={columnNames.prior}>
                    <Label color="purple" isCompact>
                      <strong>{prior.prior}</strong>
                    </Label>
                  </Td>
                  <Td dataLabel={columnNames.created}>{moment(prior.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Td>
                  <Td dataLabel={columnNames.lastModified}>{moment(prior.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Td>
                  <Td dataLabel={columnNames.actions}>
                    <Button variant="link" isInline isDanger isSmall onClick={() => deletePrior(prior)}>
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </TableComposable>
        </React.Fragment>
      )}
      {!loading && priors.length === 0 && <Text className="pf-u-p-lg">This cluster does not have any priors.</Text>}
    </React.Fragment>
  )
}

export { BMClusterPriors }
