import React from 'react'
import { PageSection, Gallery, GalleryItem, Card, CardTitle, CardBody, CardProps, Title, CardHeaderMain, CardHeader, Icon } from '@patternfly/react-core'
import { useHistory } from 'react-router-dom'
import {
  BsFileEarmarkSpreadsheet,
  RiGeminiLine,
  LuUserCog,
  PiBookOpenTextLight,
  PiPaletteLight,
  PiUsersThreeLight,
  PiWebhooksLogoLight,
  HiOutlineDocumentCheck,
  HiOutlineClipboardDocumentCheck,
  DataSourceIcon,
  AiOutlineWechatWork,
  PiToolboxLight,
  PiPackageThin,
  VscServerProcess,
  PiFolderUserLight,
} from '@lib/icons'

const DashboardHome: React.FunctionComponent = () => {
  const history = useHistory()
  const cardProps: CardProps = {
    isFullHeight: true,
    hasSelectableInput: true,
    isSelectableRaised: true,
  }

  return (
    <React.Fragment>
      <PageSection type="default" variant="default" className="c-background-light-gradient">
        <Title className="pf-u-mb-md" headingLevel="h2">
          Data collection
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/main')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiFolderUserLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Data sets</CardTitle>
              </CardHeader>
              <CardBody>See the incoming user data.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/evaluations')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <HiOutlineClipboardDocumentCheck />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Evaluations</CardTitle>
              </CardHeader>
              <CardBody>View collected product evaluations.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/channels')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <DataSourceIcon />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Channels</CardTitle>
              </CardHeader>
              <CardBody>Get an overview of where the data sets & evaluations are coming from.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data/data_definitions')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <BsFileEarmarkSpreadsheet />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Data definitions</CardTitle>
              </CardHeader>
              <CardBody>Control the type of data that is being collected.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Data refinement
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data_refinement/main')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <VscServerProcess />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Refinement models</CardTitle>
              </CardHeader>
              <CardBody>Get an overview on the refinement model processes.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/data_refinement/success_indicators')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <HiOutlineDocumentCheck />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Success indicators</CardTitle>
              </CardHeader>
              <CardBody>Get an overview on the success indicators.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Intelligence
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/model/bayesian')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <RiGeminiLine />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Intelligence</CardTitle>
              </CardHeader>
              <CardBody>Manage customisation models.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Insights
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/chat-assistant')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <AiOutlineWechatWork />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">AI chat</CardTitle>
              </CardHeader>
              <CardBody>Prompt and explore results to discover new opportunities.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/profile-analysis')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiUsersThreeLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Users</CardTitle>
              </CardHeader>
              <CardBody>Apply filters and explore results to discover new opportunities.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/formulation-analysis')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiBookOpenTextLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Formulations</CardTitle>
              </CardHeader>
              <CardBody>Apply filters and explore results to discover new opportunities.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/insights/ingredient-analysis')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiPaletteLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Ingredients</CardTitle>
              </CardHeader>
              <CardBody>Apply filters and explore results to discover new opportunities.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          Products
        </Title>
        <Gallery hasGutter>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/product/main')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiPackageThin />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Products</CardTitle>
              </CardHeader>
              <CardBody>Manage SKUs.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/product/formulations')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiBookOpenTextLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Formulations</CardTitle>
              </CardHeader>
              <CardBody>Manage product formulations.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/product/ingredients')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiPaletteLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Ingredients</CardTitle>
              </CardHeader>
              <CardBody>Manage ingredients used in formulations.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>

        <Title className="pf-u-mb-md pf-u-mt-xl" headingLevel="h2">
          API keys
        </Title>

        <Gallery hasGutter>
          {/*<GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/customer')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <LuUserCog />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">User management</CardTitle>
              </CardHeader>
              <CardBody>Access user management.</CardBody>
            </Card>
          </GalleryItem>
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/customer')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiToolboxLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">Tools</CardTitle>
              </CardHeader>
              <CardBody>Various operation tools.</CardBody>
            </Card>
          </GalleryItem>*/}
          <GalleryItem>
            <Card className="c-card-rounded" isRounded isCompact onClick={() => history.push('/api_keys')} {...cardProps}>
              <CardHeader className="pf-u-active-color-100">
                <CardHeaderMain style={{ lineHeight: 0 }}>
                  <Icon isInline size="lg">
                    <PiWebhooksLogoLight />
                  </Icon>
                </CardHeaderMain>
                <CardTitle className="pf-u-pl-sm pf-u-font-weight-light">API keys</CardTitle>
              </CardHeader>
              <CardBody>Manage API keys.</CardBody>
            </Card>
          </GalleryItem>
        </Gallery>
      </PageSection>
    </React.Fragment>
  )
}

export { DashboardHome }
